import React, { useState } from "react";
import { GridColumn } from "semantic-ui-react";
import { GridRow } from "semantic-ui-react";
import {
  Container,
  Image,
  Button,
  Grid,
  Header,
  Segment,
  Icon,
  Menu
} from "semantic-ui-react";
import globalVar from '../../globalVar';

import Logo from '../../../img/los-pinos-branco.png';

// import { LanguageSwitcher } from "../LanguageSwitcher";


export class Inicio extends React.Component {


  constructor(props) {
    super(props);
    // Aqui você pode inicializar o estado do componente ou vincular métodos.
    const queryParameters = new URLSearchParams(window.location.search);
    const lingua = queryParameters.get("lingua");
    if(lingua === "en"){
      globalVar.value = 1;
    } else {
      globalVar.value = 0;
    }

  }


  render() {

    const menusInicio = [];

    this.props.menus.forEach((menu) => {
      if (menu.onInicio)
        menusInicio.push(
          <Menu.Item
            key={menu.id}
            name={menu.id}
            onClick={() => this.props.selectPage(menu.id, "")}
            className={menu.selected ? "active" : null}
          >
            {menu.id === "catalogoerotico" ? (
                <div>
                  <Icon.Group>
                    <Icon><b>{" " + menu.icon2}</b></Icon>
                    <Icon className={menu.icon} corner="top right"></Icon>
                  </Icon.Group>
                  <br />
                  {menu.text}
                </div>
              ) : (
                <div>
                <Icon className={menu.icon}>{menu.icon2}</Icon>
                <br />
                {globalVar.value === 0 ? (menu.text) : (menu.textEN)}
              </div>
              )}
          </Menu.Item>
        );
    });


    return (

      <Container fluid className="inicio-background h-100 contentor-home" textAlign="center">
        <Segment basic className="w-100 h-100 inicio">
          <Grid centered className="h-100">

            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Image src={Logo} className="logoInicio" centered></Image>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column className="divbtn">
                  {/* <Menu widths={menusInicio.length}>{menusInicio}</Menu> */}
                    <a className="avaliar" href={globalVar.value === 0 ? 'http://menu.motelospinos.com/menu?LINGUA=es' : 'http://menu.motelospinos.com/menu?LINGUA=en'}><Icon className="food"></Icon>{globalVar.value === 0 ? 'Menú' : 'Menu'}</a>
                    <a className="avaliar" href={globalVar.value === 0 ? 'http://menu.motelospinos.com/menu/eroticos?LINGUA=es' : 'http://menu.motelospinos.com/menu/eroticos?LINGUA=en'}>
                      <Icon className="icons"><i aria-hidden="true" class="icon"><b> 18</b></i><i aria-hidden="true" class="top right corner icon plus"></i></Icon>
                        {globalVar.value === 0 ? 'Eróticos' : 'Erotic'}
                    </a>
                    <a className="avaliar" href={globalVar.value === 0 ? 'http://survey.motelospinos.com/?HOTEL=4&LINGUA=es' : 'http://survey.motelospinos.com/?HOTEL=4&LINGUA=en'}><Icon className="check"></Icon>{globalVar.value === 0 ? 'Evaluar motel' : 'Review Motel'}</a>
                </Grid.Column>
              </Grid.Row>
            </Grid>

          </Grid>
        </Segment>
     </Container>
     
    );
  }
}






