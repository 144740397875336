import React from "react";
import { Menu, Icon } from "semantic-ui-react";

import globalVar from '../globalVar';


export class MyHeader extends React.Component {


  render() {

    const menuLocation = [];
    const menusHome = [];

    if(globalVar.value==0)  {

      //HEADER ESPANHOL
      this.props.menus.forEach((menu) => {
        if (menu.selected && menu.id !== "inicio") {
          menuLocation.push(
            <Menu.Item
              className="noBorderRight"
              key={menu.id}
              name={menu.id}
              onClick={() => this.props.selectPage(menu.id, "")}
            >
              <b>{menu.text}</b>
            </Menu.Item>
          );
        }
      });

      this.props.menus.forEach((menu) => {
        if (menu.onHome)
          menusHome.push(
            <Menu.Item
              key={menu.id}
              name={menu.id}
              onClick={() => this.props.selectPage(menu.id, "")}
              >
                <Icon className={menu.icon}></Icon>
            </Menu.Item>
          );
      });

    } else {

      //HEADER INGLES
      this.props.menus.forEach((menu) => {
        if (menu.selected && menu.id !== "inicio") {
          menuLocation.push(
            <Menu.Item
              className="noBorderRight"
              key={menu.id}
              name={menu.id}
              onClick={() => this.props.selectPage(menu.id, "")}
            >
              <b>{menu.textEN}</b>
            </Menu.Item>
          );
        }
      });
  
      this.props.menus.forEach((menu) => {
        if (menu.onHome)
          menusHome.push(
            <Menu.Item
              key={menu.id}
              name={menu.id}
              onClick={() => this.props.selectPage(menu.id, "")}
              >
                 <Icon className={menu.icon}></Icon>
            </Menu.Item>
          );
      });


    }
    







    return (
      <header>
        <Menu size="large">
          {/* <Menu.Item name="menu" onClick={this.props.openSideMenu}>
            <Icon name="sidebar"></Icon>
          </Menu.Item> */}
          
          {menusHome}
          {menuLocation}

        </Menu>


      </header>
    );
  }
}
