import React from "react";
import { Menu, Icon } from "semantic-ui-react";
import globalVar from '../globalVar';

export class MyFooter extends React.Component {
  handleItemClick = (event) => {
    alert(event.target.name);
  };

  render() {

    const menusFooter = [];
    
    this.props.menus.forEach((menu) => {
      if (menu.onFooter)
        menusFooter.push(
          <Menu.Item
            key={menu.id}
            name={menu.id}
            onClick={() => this.props.selectPage(menu.id, "")}
            className={menu.selected ? "active" : null}
          >
            {menu.id === "catalogoerotico" ? (
              <div>
                <Icon.Group>
                  <Icon>
                    <b>{menu.icon2}</b>
                  </Icon>
                  <Icon name={menu.icon} corner="top right"></Icon>
                </Icon.Group>
                <b> {globalVar.value == 0 ? (' ' +menu.text) : (' ' +menu.textEN)}</b>
              </div>
            ) : (
              <div>
                <Icon name={menu.icon}></Icon>
                <b>{globalVar.value == 0 ? (' ' +menu.text) : (' ' +menu.textEN)}</b>
              </div>
            )}
          </Menu.Item>
        );
    });

    return (
      <footer>
        <Menu widths={menusFooter.length}>{menusFooter}</Menu>
      </footer>
    );
  }
}
