import React, { useState } from "react";

import { MyHeader } from "./Header/MyHeader";
import { MyCentro } from "./Centro/MyCentro";
import { MyFooter } from "./Footer/MyFooter";
import { menus } from "./Dados/menus";
import globalVar from './globalVar';

// import Dropdownlang from "./Dropdownlang";


import {
  Icon,
  Menu,
  Segment,
  Sidebar,
  Dimmer,
  Loader,
} from "semantic-ui-react";

import "semantic-ui-css/semantic.min.css";
import "./App.css";



export class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      sideBarVisible: false,
      menus: menus.filter((menu) => menu.active),
      catalogoEroticoIsLogIn: false,
      tabacoIsLogIn: false,
      carrinho: [],
    };

    const queryParameters = new URLSearchParams(window.location.search);
    const lingua = queryParameters.get("lingua");
    if(lingua === "en"){
      globalVar.value = 1;
    } else {
      globalVar.value = 0;
    }

  }


  

  openSideMenu = () => {
    this.setState({ sideBarVisible: true });
  };
  closeSideMenu = () => {
    this.setState({ sideBarVisible: false });
  };


  //TRADUÇOES
  changeLanguageToEn = () => {
    // const queryParameters = new URLSearchParams(window.location.search);
    // const lingua = queryParameters.get("lingua");
    // lingua.setState({value: "en"})


    globalVar.value = 1;
    this.setState({value: 1})
  };
  changeLanguageToEs = () => {
    // const queryParameters = new URLSearchParams(window.location.search);
    //  const lingua = queryParameters.get("lingua");
    //  lingua.setState({value: "es"})

    globalVar.value = 0;
    this.setState({value: 0})
  };



  openLanguageSwitcher = () => {
    alert("open language");
  };
  closeLanguageSwitcher = () => {
    alert("close language");
  };

  selectPage = (id, subid) => {
    this.setState({
      menus: this.state.menus.map((menu) => {
        if (menu.id === id) {
          return {
            ...menu,
            selected: true,
            subid: subid,
          };
        } else {
          return {
            ...menu,
            selected: false,
          };
        }
      }),
    });
    console.log(this.state.menus);
    this.closeSideMenu();
  };

  loginCatalogoErotico = () => {
    this.setState({
      catalogoEroticoIsLogIn: true,
    });
  };
  loginTabaco = () => {
    this.setState({
      tabacoIsLogIn: true,
    });
  };

  loadingOpen = () => {
    this.setState({
      loading: true,
    });
  };
  loadingClose = () => {
    this.setState({
      loading: false,
    });
  };

  addToCarrinho = (produtoN) => {
    let added = false;

    this.setState({
      carrinho: this.state.carrinho.map((produto) => {
        if (produto.id === produtoN.id) {
          added = true;
          return {
            ...produto,
            qtd: produto.qtd + 1,
          };
        } else {
          return {
            ...produto,
          };
        }
      }),
    });

    if (!added) {
      produtoN.qtd = 1;
      this.setState({ carrinho: [...this.state.carrinho, produtoN] });
    }
  };

  

  removeFromCarrinho = (produtoR) => {
    this.setState({
      carrinho: this.state.carrinho.map((produto) => {
        if (produto.id === produtoR.id) {
          return {
            ...produto,
            qtd: produto.qtd - 1,
          };
        } else {
          return {
            ...produto,
          };
        }
      }),
    });




  };

  render() {

    let vh = window.innerHeight * 100;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 100;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    const menusSideBar = [];
    this.state.menus.forEach((menu) => {
      if (menu.onSideBar)
        menusSideBar.push(
          <Menu.Item
            key={menu.id}
            as="a"
            onClick={() => this.selectPage(menu.id, "")}
            className={menu.selected ? "active" : null}
          >
            {menu.id === "catalogoerotico" ? (
              <div>
                <Icon.Group>
                  <Icon>
                    <b>{menu.icon2}</b>
                  </Icon>
                  <Icon className={menu.icon} corner="top right"></Icon>
                </Icon.Group>
                <br />
                {menu.text}
              </div>
            ) : (
              <div>
                <Icon className={menu.icon}>{menu.icon2}</Icon>
                <br />
                {menu.text}
              </div>
            )}
          </Menu.Item>
        );
    });

   

    let loading = "";
    if (this.state.loading) {
      loading = (
        <Dimmer inverted active>
          <Loader inverted content="Loading" />
        </Dimmer>
      );
    }



    return (


      <div className="h-100">

        <div className="btnLang square x-axis">
            {globalVar.value == 0 ? 
                <a onClick={this.changeLanguageToEn}><Icon.Group><Icon className="globe" ></Icon></Icon.Group> EN</a> 
                : 
                <a onClick={() => this.changeLanguageToEs()}><Icon.Group><Icon className="globe" ></Icon></Icon.Group> ES</a>
            }
        </div>

        <Sidebar.Pushable as={Segment}>

          <Sidebar
            as={Menu}
            animation="push"
            icon="labled"
            vertical
            direction="left"
            onHide={this.closeSideMenu}
            visible={this.state.sideBarVisible}
            width="thin"
          >

            {menusSideBar}

            {/* <div className="avaliar-menu">
              <a className="avaliar" href="https://primeclub.pt/survey/habana?HOTEL=1"><Icon className="check"></Icon>Avaliar Estadia</a>
            </div>  */}
          </Sidebar>
          
          <Sidebar.Pusher
            className="allCentro border-0 h-100"
            dimmed={this.state.sideBarVisible}
          >
            <div id="main" className="h-100">
              {this.state.menus.filter((menu) => menu.selected)[0].id ==="inicio" ? null : (
                <MyHeader
                  menus={this.state.menus}
                  openSideMenu={this.openSideMenu}
                  selectPage={this.selectPage}
                />
              )}

              <MyCentro
                selectPage={this.selectPage}
                menus={this.state.menus}
                loginCatalogoErotico={this.loginCatalogoErotico}
                catalogoEroticoIsLogIn={this.state.catalogoEroticoIsLogIn}
                loginTabaco={this.loginTabaco}
                tabacoIsLogIn={this.state.tabacoIsLogIn}
              />

              
              {this.state.menus.filter((menu) => menu.selected)[0].id ==="inicio" || 
              this.state.menus.filter((menu) => menu.selected)[0].id === "catalogoerotico" ? null : (
                <MyFooter
                  selectPage={this.selectPage}
                  menus={this.state.menus}
                />
              )}
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        {loading}
      </div>
    );
  }
}
