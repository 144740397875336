export const menus = [
  {
    id: "inicio",
    text: "Inicio",
    textEN: "Home",
    icon: "home",
    active: true,
    selected: true,
    onInicio: false,
    onFooter: false,
    onSideBar: true,
    onHome: true,
  },
  // {
  //   id: "home",
  //   text: "Homee",
  //   icon: "home",
  //   active: true,
  //   selected: false,
  //   onInicio: false,
  //   onFooter: false,
  //   onSideBar: false,
  // },
  {
    id: "home",
    text: "Menú",
    textEN: "Menu",
    icon: "food",
    active: true,
    selected: false,
    onInicio: true,
    onFooter: false,
    onSideBar: false,
  },
  {
    id: "menu",
    subid: "", // sub menu principal, para colocar diferentes paginas de subcategorias
    text: "Comida",
    textEN: "Food",
    icon: "food",
    active: true,
    selected: false,
    onInicio: false,
    onFooter: true,
    onSideBar: true,
  },
  {
    id: "bebidas",
    subid: "", // sub menu principal
    text: "Bebidas",
    textEN: "Drinks",
    icon: "bar",
    active: true,
    selected: false,
    onInicio: false,
    onFooter: true,
    onSideBar: true,
  },
  
  // {
  //   id: "sugestoes",
  //   text: "Sugestões",
  //   icon: "check",
  //   img: "./img/sugestoes/sugestoes.jpg",
  //   active: true,
  //   selected: false,
  //   onInicio: false,
  //   onFooter: false,
  //   onSideBar: true,
  // },
  // {
  //   id: "tabaco",
  //   text: "Tabaco",
  //   icon: "icon-tabaco",
  //   img: "./img/tabaco/tabaco.jpg",
  //   active: true,
  //   selected: false,
  //   onInicio: false,
  //   onFooter: true,
  //   onSideBar: true,
  // },
  {
    id: "catalogoerotico",
    text: "Eróticos",
    textEN: "Erotic",
    icon: "plus",
    icon2: "18",
    image: "",
    active: true,
    selected: false,
    onInicio: false,
    onFooter: true,
    onSideBar: true,
  },
  {
    id: "outros",
    text: "Otros",
    textEN: "Others",
    icon: "icon-tabaco",
    img: "./img/tabaco/tabaco.jpg",
    active: true,
    selected: false,
    onInicio: false,
    onFooter: true,
    onSideBar: true,
  },
  {
    id: "tv",
    text: "Televisão",
    textEN: "menuEN",
    icon: "tv",
    active: false,
    selected: false,
    onInicio: false,
    onFooter: false,
    onSideBar: true,
  },

  {
    id: "carrinho",
    text: "Carrinho",
    textEN: "menuEN",
    icon: "cart",
    active: false,
    selected: false,
    onInicio: false,
    onFooter: false,
    onSideBar: true,
  }
];
