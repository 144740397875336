export const options = [
  {
    active: true,
    id: "bebidasrefrescantes",
    title: "Bebidas Refrescantes",
    titleEN: "Fresh Drinks",
    img: "./img/bebidas/refrigerantes/refrigerantes.jpg",
  },
  {
    active: true,
    id: "cervejascidras",
    title: "Cervezas",
    titleEN: "Beers",
    img: "./img/bebidas/cervejascidras/cervejascidras.jpg",
  },
  {
    active: true,
    id: "especiais",
    title: "Bebidas Preparadas | Sangrias",
    titleEN: "Prepared Drinks | Sangria",
    img: "./img/bebidas/especiais/especiais.jpg",
  },
  {
    active: true,
    id: "vinhos",
    title: "Vinos",
    titleEN: "Wines",
    img: "./img/bebidas/vinhos/vinhos.jpg",
  },
  {
    active: true,
    id: "bebidasespirituosas",
    title: "Bebidas Espirituosas",
    titleEN: "Spirit Drinks",
    img: "./img/bebidas/bebidasespirituosas/bebidasespirituosas.jpg",
  },
  // {
  //   active: true,
  //   id: "vinhosgenerosos",
  //   title: "Vinhos do Porto",
  //   img: "./img/bebidas/vinhosgenerosos/vinhosgenerosos.jpg",
  // },
  {
    active: true,
    id: "whisky",
    title: "Whisky´s",
    titleEN: "Whisky´s",
    img: "./img/bebidas/whisky/whisky.jpg",
  },
  {
    active: true,
    id: "espumanteschampagne",
    title: "Espumoso y champán",
    titleEN: "Champagne and Sparkling Wines",
    img: "./img/bebidas/espumanteschampagne/espumanteschampagne.jpg",
  },
    // {
  //   active: true,
  //   id: "aguassumos",
  //   title: "Águas & Sumos",
  //   img: "./img/bebidas/aguassumos/aguassumos.jpg",
  // },
];
