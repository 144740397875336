
import React from "react";
import { GridColumn } from "semantic-ui-react";
import { GridRow } from "semantic-ui-react";
import {
  Container,
  Image,
  Button,
  Grid,
  Header,
  Segment
} from "semantic-ui-react";

import Logo from '../../../img/motel-los-pinos-logo.png';
import Extensao from '../../../img/extensao.png';
import LosPinos from '../../../img/los-pinos-branco.png';



export class Home extends React.Component {


  render() {
    const menusInicio = [];

    this.props.menus.forEach((menu) => {
      if (menu.onInicio)
        menusInicio.push(
          <Grid.Column mobile={8} tablet={8} computer={8} key={menu.id}>
            <Button
              fluid
              size="large"
              compact
              name={menu.id}
              onClick={() => this.props.selectPage(menu.id, "")}
            >
              
              {menu.text}
            </Button>
          </Grid.Column>
        );
    });
    return (
      <Container fluid className="inicio-background h-100" textAlign="center">
        <Segment basic className="w-100 h-100">
          <Grid centered className="h-100">

            <Grid.Row verticalAlign="middle">
              <Grid.Column only="widescreen" computer={4}></Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                  <Image src={LosPinos} className="los_pinos" centered />
                  <h1 centered>ROOM</h1>
                  <h2 centered>SERVICE</h2>
                  <Image src={Extensao} className="extensao" centered ></Image>
              </Grid.Column>
              <Grid.Column only="widescreen" computer={4}></Grid.Column>
            </Grid.Row>

            {/* <Grid.Row verticalAlign="middle">
              <Grid.Column only="widescreen" computer={4}></Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                  <Image src={Logo} size="small" centered />
              </Grid.Column>
              <Grid.Column only="widescreen" computer={4}></Grid.Column>
            </Grid.Row> */}

            <Grid.Row verticalAlign="middle">
              <Grid.Column only="widescreen" computer={4}></Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Header as="h4" color="white" textAlign="center">
                  <div className="text-color-preto">
                    <p>PRECIOS EN MONEDA NACIONAL M.N.<br></br> I.V.A INCLUIDO</p>
                  </div>
                </Header>
              </Grid.Column>
              <Grid.Column only="widescreen" computer={4}></Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    );
  }
}




