import React from "react";
import {
  Grid,
  Container,
  Item,
  Header,
  Card,
  Image,
  Button,
} from "semantic-ui-react";

import { produtos } from "../../Dados/produtos";

export class Tabaco extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      produtos: produtos.filter(
        (produto) => produto.active && produto.grupo === "tabaco"
      ),
    };
  }

  loginTabaco = (event) => {
    event.preventDefault();
    this.props.loginTabaco();
  };

  render() {
    let ItemsJSX = [];
    if (this.props.tabacoIsLogIn) {
      ItemsJSX.push(
        <Grid.Column
          stretched
          mobile={16}
          tablet={16}
          computer={16}
          key="banner"
          fluid
          className="padding-0"
        >
          <div
            className="banner-inside-category"
            style={{
              backgroundImage: `url(${"" + this.props.img})`,
            }}
          >
            <div class="overlay">
              <Header as="h3" inverted className="banner-label-position">
                {this.props.title}
              </Header>
            </div>
          </div>
        </Grid.Column>
      );

      this.state.produtos.forEach((produto) => {
        ItemsJSX.push(
          <Grid.Column
            fluid
            stretched
            mobile={16}
            tablet={8}
            computer={4}
            key={produto.id}
            onClick={() => this.modalOpen(produto.img)}
          >
            <Card fluid className="padding-10 padding-top-15 column-card">
              <Image centered size="tiny" src={produto.img} />
              <Card.Content className="border-0">
                {produto.descricao2 !== "" ? ( // para o caso
                  <div>
                    <Card.Header
                      textAlign="center"
                      className="grid-produto-title"
                    >
                      {produto.descricao2}
                    </Card.Header>
                  </div>
                ) : null}

                <Card.Header textAlign="center" className="grid-produto-title">
                  {produto.name}
                </Card.Header>
                <Card.Header textAlign="center" className="grid-produto-preco">
                  {produto.preco} {" " + produto.moeda}
                </Card.Header>

                {produto.descricao !== "" ? (
                  <div>
                    <br />
                    <Item.Description className="text-align-center">
                      {produto.descricao}
                    </Item.Description>
                  </div>
                ) : null}

                {produto.extra !== "" ? (
                  <Item.Extra className="text-align-center">
                    {produto.extra}
                  </Item.Extra>
                ) : null}
              </Card.Content>
            </Card>
          </Grid.Column>
        );
      });
    } else {
      ItemsJSX.push(
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Grid centered className="margin-top10">
            <Grid.Column mobile={14} tablet={10} computer={8}>
              <Grid centered className="margin-top10">
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Header as="h3" centered textAlign="center">
                    Página destinada a maiores de 18.
                  </Header>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    fluid
                    centered
                    type="submit"
                    onClick={this.loginTabaco}
                  >
                    Entrar
                  </Button>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    fluid
                    centered
                    type="submit"
                    onClick={() => this.props.selectPage("inicio", "")}
                  >
                    Sair
                  </Button>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      );
    }

    return (
      <div className="mainP1">
        <div className="mainCentroP1 bg-grey">
          <Container fluid className="h-100">
            <Grid
              centered
              columns={1}
              fluid
              className="h-100 margin-0 padding-0"
            >
              <Grid.Row
                verticalAlign={this.props.tabacoIsLogIn ? "top" : "middle"}
                className="padding-0"
              >
                <Grid.Column>
                  <Grid
                    centered={this.props.tabacoIsLogIn ? false : true}
                    className="margin-top0"
                  >
                    {ItemsJSX}
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}
