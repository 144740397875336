export const options = [
  {
    active: true,
    id: "tabaco",
    title: "Tabaco",
    titleEN: "Tobacco",
    img: "./img/tabaco/tabaco.jpg",
  },
  {
    active: true,
    id: "preservativos",
    title: "Preservativos",
    titleEN: "Condoms",
    img: "./img/outros/preservativos.jpg",
  },
  {
    active: true,
    id: "higiene",
    title: "Higiene",
    titleEN: "Hygiene",
    img: "./img/outros/higiene.jpg",
  },
  {
    active: true,
    id: "medicine",
    title: "Medicina",
    titleEN: "Medicine",
    img: "./img/outros/medicine.jpg",
  },
  {
    active: true,
    id: "varios",
    title: "Varios",
    titleEN: "Other",
    img: "./img/outros/variados.jpg",
  },
];
