export const produtos = [
  /*############################################ MENU */
  /* PEQUENO ALMOÇO */
  {
    active: true,
    id: "Continental",
    name: "DESAYUNO BAJA",
    nameEN: "DESAYUNO BAJA",
    img: "./img/motel-los-pinos-logo.png",
    descricao:"Huevos con tocino, jamón, queso y frijoles, Pan tostado o tortillas, Jugo de naranja natural, Café o Té",
    descricao2:"",
    extra: "",
    descricaoEN:"Huevos con tocino, jamón, queso y frijoles, Pan tostado o tortillas, Jugo de naranja natural, Café o Té",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 130",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "pequenoalmoco",
  },
  {
    active: true,
    id: "California",
    name: "DESAYUNO CALIFORNIA",
    nameEN: "DESAYUNO CALIFORNIA",
    img: "./img/motel-los-pinos-logo.png",
    descricao:"Huevos con tocino y papa hash brown, Hot cakes o waffles, Jugo (Varios Sabores), Café o Té",
    descricao2:"",
    extra: "",
    descricaoEN:"Huevos con tocino y papa hash brown, Hot cakes o waffles, Jugo (Varios Sabores), Café o Té",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 125",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "pequenoalmoco",
  },
  {
    active: true,
    id: "Pandulce",
    name: "Pan dulce",
    nameEN: "Pan dulce",
    img: "./img/motel-los-pinos-logo.png",
    descricao:"",
    descricao2:"",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 45",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "pequenoalmoco",
  },
  {
    active: true,
    id: "Hotcakes",
    name: "Hot cakes o waffles",
    nameEN: "Hot cakes o waffles",
    img: "./img/motel-los-pinos-logo.png",
    descricao:"",
    descricao2:"",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 80",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "pequenoalmoco",
  },
  {
    active: true,
    id: "Huevos",
    name: "Huevos al gusto",
    nameEN: "Huevos al gusto",
    img: "./img/motel-los-pinos-logo.png",
    descricao:"",
    descricao2:"",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 90",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "pequenoalmoco",
  },
  {
    active: true,
    id: "Omelette",
    name: "Omelette al gusto",
    nameEN: "Omelette al gusto",
    img: "./img/motel-los-pinos-logo.png",
    descricao:"",
    descricao2:"",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 90",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "pequenoalmoco",
  },
  {
    active: true,
    id: "Chilaquiles",
    name: "Chilaquiles rojos o verdes	",
    nameEN: "Chilaquiles rojos o verdes	",
    img: "./img/motel-los-pinos-logo.png",
    descricao:"",
    descricao2:"",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 90",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "pequenoalmoco",
  },
  {
    active: true,
    id: "naranja",
    name: "Jugo de naranja natural",
    nameEN: "Jugo de naranja natural",
    img: "./img/motel-los-pinos-logo.png",
    descricao:"",
    descricao2:"",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 50",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "pequenoalmoco",
  },
  {
    active: true,
    id: "Leche",
    name: "Leche",
    nameEN: "Leche",
    img: "./img/motel-los-pinos-logo.png",
    descricao:"",
    descricao2:"",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 30",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "pequenoalmoco",
  },
  {
    active: true,
    id: "Chocomilk",
    name: "Chocomilk",
    nameEN: "Chocomilk",
    img: "./img/motel-los-pinos-logo.png",
    descricao:"",
    descricao2:"",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 35",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "pequenoalmoco",
  },
  {
    active: true,
    id: "Oreo",
    name: "Oreo frapuccino",
    nameEN: "Oreo frapuccino",
    img: "./img/motel-los-pinos-logo.png",
    descricao:"",
    descricao2:"",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 45",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "pequenoalmoco",
  },
  {
    active: true,
    id: "Cafeote",
    name: "Café o Té",
    nameEN: "Café o Té",
    img: "./img/motel-los-pinos-logo.png",
    descricao:"",
    descricao2:"",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 30",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "pequenoalmoco",
  },
  


  /* ENTRADAS E SOPAS */
  {
    active: true,
    id: "Maruchan",
    name: "Sopa Maruchan",
    nameEN: "Sopa Maruchan",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 45",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "entradassopas",
  },
  {
    active: true,
    id: "Papas",
    name: "Papas a la francesa",
    nameEN: "Papas a la francesa",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 55",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "entradassopas",
  },
  {
    active: true,
    id: "Nachos",
    name: "Nachos con queso",
    nameEN: "Nachos con queso",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 80",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "entradassopas",
  },
  {
    active: true,
    id: "Quesadillas",
    name: "Quesadillas",
    nameEN: "Quesadillas",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 90",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "entradassopas",
  },
  {
    active: true,
    id: "Sincronizadas",
    name: "Sincronizadas",
    nameEN: "Sincronizadas",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 100",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "entradassopas",
  },
  {
    active: true,
    id: "Palitos",
    name: "Palitos de mozzarella",
    nameEN: "Palitos de mozzarella",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 80",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "entradassopas",
  },
  {
    active: true,
    id: "Pizza",
    name: "Pizza",
    nameEN: "Pizza",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 100",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "entradassopas",
  },
  {
    active: true,
    id: "Nuggets",
    name: "Nuggets de pollo",
    nameEN: "Nuggets de pollo",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 90",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "entradassopas",
  },
  {
    active: true,
    id: "Alitas",
    name: "Alitas de pollo",
    nameEN: "Alitas de pollo",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 115",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "entradassopas",
  },
  {
    active: true,
    id: "Camarones",
    name: "Camarones con papas",
    nameEN: "Camarones con papas",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 130",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "entradassopas",
  },
  {
    active: true,
    id: "Cocktail",
    name: "Cocktail de camarón",
    nameEN: "Cocktail de camarón",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 130",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "entradassopas",
  },
 
  /* SALADAS */
  {
    active: true,
    id: "Atun",
    name: "Ensalada de atún",
    nameEN: "Ensalada de atún",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 100",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "saladas",
  },
  {
    active: true,
    id: "Pollo",
    name: "Ensalada de pollo",
    nameEN: "Ensalada de pollo",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 110",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "saladas",
  },
  {
    active: true,
    id: "Cesar",
    name: "Ensalada César",
    nameEN: "Ensalada César",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 100",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "saladas",
  },
  {
    active: true,
    id: "Cesar2",
    name: "Ensalada César",
    nameEN: "Ensalada César",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Pida su ensalada César con pollo o añádale una orden de asada a sus papas.¡Haga su combinación ganadora!",
    descricao2: "Tortillas • Totopos • Queso de Nachos • Frijoles • Arroz • Jamón",
    extra: "+ 15 M.N. por un adicional",
    descricaoEN:"Pida su ensalada César con pollo o añádale una orden de asada a sus papas.¡Haga su combinación ganadora!",
    descricao2EN:"Tortillas • Totopos • Queso de Nachos • Frijoles • Arroz • Jamón",
    extraEN: "+ 15 M.N. for one extra",
    preco: "$ 100",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "saladas",
  },
  {
    active: true,
    id: "Cesar3",
    name: "Ensalada César",
    nameEN: "Ensalada César",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Pida su ensalada César con pollo o añádale una orden de asada a sus papas.¡Haga su combinación ganadora!",
    descricao2: "Pan Tostado • Papa Hash Brown • Aguacate • Huevo • Tocino",
    extra: "+ 20 M.N. por un adicional",
    descricaoEN:"Pida su ensalada César con pollo o añádale una orden de asada a sus papas.¡Haga su combinación ganadora!",
    descricao2EN:"Pan Tostado • Papa Hash Brown • Aguacate • Huevo • Tocino",
    extraEN: "+ 20 M.N. for one extra",
    preco: "$ 100",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "saladas",
  },
  {
    active: true,
    id: "Cesar4",
    name: "Ensalada César",
    nameEN: "Ensalada César",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Pida su ensalada César con pollo o añádale una orden de asada a sus papas.¡Haga su combinación ganadora!",
    descricao2: "Pechuga de pollo",
    extra: "+ 35 M.N.",
    descricaoEN:"Pida su ensalada César con pollo o añádale una orden de asada a sus papas.¡Haga su combinación ganadora!",
    descricao2EN:"Pechuga de pollo",
    extraEN: "+ 35 M.N.",
    preco: "$ 100",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "saladas",
  },
  {
    active: true,
    id: "Cesar5",
    name: "Ensalada César",
    nameEN: "Ensalada César",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Pida su ensalada César con pollo o añádale una orden de asada a sus papas.¡Haga su combinación ganadora!",
    descricao2: "Orden de asada",
    extra: "+ 40 M.N.",
    descricaoEN:"Pida su ensalada César con pollo o añádale una orden de asada a sus papas.¡Haga su combinación ganadora!",
    descricao2EN:"Orden de asada",
    extraEN: "+ 40 M.N.",
    preco: "$ 100",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "saladas",
  },
  
 


  /* CARNES */
  {
    active: true,
    id: "Flautas",
    name:"Flautas",
    nameEN:"Flautas",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 145",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "platillos",
  },
  {
    active: true,
    id: "Pechuga",
    name:"Pechuga de pollo a la plancha",
    nameEN:"Pechuga de pollo a la plancha",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 130",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "platillos",
  },
  {
    active: true,
    id: "Bistec",
    name:"Bistec",
    nameEN:"Bistec",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 180",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "platillos",
  },
  {
    active: true,
    id: "Camaronesalmojo",
    name:"Camarones al mojo de ajo",
    nameEN:"Camarones al mojo de ajo",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 175",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "platillos",
  },
  

  {
    active: true,
    id: "Cacahuates",
    name: "Cacahuates surtidos",
    nameEN: "Cacahuates surtidos",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 50",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "acompanhamentos",
  },
  {
    active: true,
    id: "Sabritas",
    name: "Sabritas surtidas",
    nameEN: "Sabritas surtidas",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 55",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "acompanhamentos",
  },
  {
    active: true,
    id: "Aceitunas",
    name: "Aceitunas preparadas",
    nameEN: "Aceitunas preparadas",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 55",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "acompanhamentos",
  },
  {
    active: true,
    id: "Salchichas",
    name: "Salchichas preparadas",
    nameEN: "Salchichas preparadas",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 70",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "acompanhamentos",
  },
  {
    active: true,
    id: "Guacamole",
    name: "Guacamole con totopos",
    nameEN: "Guacamole con totopos",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 85",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "acompanhamentos",
  },

  /* PARA COMER CON LAS MANOS */
  {
    active: true,
    id: "Jamonequeso",
    name: "Sandwich de jamón y queso",
    nameEN: "Sandwich de jamón y queso",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 90",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "comerconlasmanos",
  },
  {
    active: true,
    id: "Sandwichdeatun",
    name: "Sandwich de atún",
    nameEN: "Sandwich de atún",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 90",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "comerconlasmanos",
  },
  {
    active: true,
    id: "Sandwichdepollo",
    name: "Sandwich de pollo",
    nameEN: "Sandwich de pollo",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 100",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "comerconlasmanos",
  },
  {
    active: true,
    id: "Clubsandwich",
    name: "Club sandwich",
    nameEN: "Club sandwich",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 120",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "comerconlasmanos",
  },
  {
    active: true,
    id: "Hamburguesadepollo",
    name: "Hamburguesa de pollo o res",
    nameEN: "Hamburguesa de pollo o res",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 120",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "comerconlasmanos",
  },
  {
    active: true,
    id: "Tortadecarne",
    name: "Torta de carne asada",
    nameEN: "Torta de carne asada",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 120",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "comerconlasmanos",
  },
  {
    active: true,
    id: "Burritoconcarne",
    name: "Burrito con carne asada o camarón",
    nameEN: "Burrito con carne asada o camarón",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 100",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "comerconlasmanos",
  },
 
  /* POSTRES */
  {
    active: true,
    id: "Pastelchocolate",
    name: "Pastel de chocolate",
    nameEN: "Pastel de chocolate",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 65",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "postres",
  },
  {
    active: true,
    id: "Paydequeso",
    name: "Pay de queso",
    nameEN: "Pay de queso",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 65",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "postres",
  },
  {
    active: true,
    id: "Helado",
    name: "Helado	 473ml",
    nameEN: "Helado	 473ml",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 80",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "postres",
  },
  {
    active: true,
    id: "Chocolatedebarra",
    name: "Chocolate de barra",
    nameEN: "Chocolate de barra",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 35",
    moeda: "M.N.",
    grupo: "menu",
    subgrupo: "postres",
  },
 
  
  /*############################################ MENU */

  /*############################################ BEBIDAS */
  /* VINHOS */
  {
    active: true,
    id: "Blancdeblancs",
    name: "Blanc de Blancs",
    nameEN: "Blanc de Blancs",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "BLANCO",
    descricao2: "MINI (187ml) - 90 M.N.",
    extra: "BOTELLA (750ml) - 380 M.N.",
    descricaoEN:"wHITE WINE",
    descricao2EN:"MINI (187ml) - 90 M.N.",
    extraEN: "BOTTLE (750ml) - 380 M.N.",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "vinhos",
    // onsugestoes: true,
  },
  {
    active: true,
    id: "Chardonnay",
    name: "Chardonnay",
    nameEN: "Chardonnay",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "BLANCO",
    descricao2: "BOTELLA (750ml) - 380 M.N.",
    extra: "",
    descricaoEN:"WHITE WINE",
    descricao2EN:"BOTTLE (750ml) - 380 M.N.",
    extraEN: "",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "vinhos",
  },
  {
    active: true,
    id: "Blancdezinfandel",
    name: "Blanc de Zinfandel",
    nameEN: "Blanc de Zinfandel",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "ROSADO",
    descricao2: "MINI (187ml) - 90 M.N.",
    extra: "BOTELLA (750ml) - 380 M.N.",
    descricaoEN:"ROSÉ WINE",
    descricao2EN:"MINI (187ml) - 90 M.N.",
    extraEN: "BOTTLE (750ml) - 380 M.N.",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "vinhos",
  },
  {
    active: true,
    id: "Cabernetsauvignon",
    name: "Cabernet Sauvignon",
    nameEN: "Cabernet Sauvignon",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "TINTO",
    descricao2: "MINI (187ml) - 90 M.N.",
    extra: "BOTELLA (750ml) - 380 M.N.",
    descricaoEN:"RED WINE",
    descricao2EN:"MINI (187ml) - 90 M.N.",
    extraEN: "BOTTLE (750ml) - 380 M.N.",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "vinhos",
  },
  {
    active: true,
    id: "Zinfandel",
    name: "Zinfandel",
    nameEN: "Zinfandel",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "BLANCO",
    descricao2: "BOTELLA (750ml) - 380 M.N.",
    extra: "",
    descricaoEN:"WHITE WINE",
    descricao2EN:"BOTTLE (750ml) - 380 M.N.",
    extraEN: "",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "vinhos",
  },
  {
    active: true,
    id: "Lambrusco",
    name: "Lambrusco",
    nameEN: "Lambrusco",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "DULCE",
    descricao2: "MINI (187ml) - 90 M.N.",
    extra: "BOTELLA (750ml) - 380 M.N.",
    descricaoEN:"SWEET WINE",
    descricao2EN:"MINI (187ml) - 90 M.N.",
    extraEN: "BOTTLE (750ml) - 380 M.N.",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "vinhos",
  },


  /* VINHOS GENEROSO */
  {
    active: true,
    id: "Porto20Anos",
    name: "Porto 20 anos",
    nameEN: "Porto 20 anos",
    img: "./img/bebidas/vinhosgenerosos/portwinebottle.jpg",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "5,50",
    moeda: "€",
    grupo: "bebidas",
    subgrupo: "vinhosgenerosos",
  },
  {
    active: true,
    id: "PortoTawny",
    name: "Porto Tawny",
    nameEN: "Porto Tawny",
    img: "./img/bebidas/vinhosgenerosos/portwinebottle.jpg",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "4,00",
    moeda: "€",
    grupo: "bebidas",
    subgrupo: "vinhosgenerosos",
  },

  /* ESPUMANTES CHAMPAGNE */
  {
    active: true,
    id: "Champbrule",
    name: "Champbrulé",
    nameEN: "Champbrulé",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "750ml",
    descricao2: "",
    extra: "",
    descricaoEN:"750ml",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 440",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "espumanteschampagne",
  },
  {
    active: true,
    id: "Moetchandon",
    name: "Moët & Chandon",
    nameEN: "Moët & Chandon",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "750ml",
    descricao2: "",
    extra: "",
    descricaoEN:"750ml",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 2200",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "espumanteschampagne",
  },

  /* REFRIGERANTES */
  {
    active: true,
    id: "Aguanatural",
    name: "Agua natural",
    nameEN: "Natural Water",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "25 M.N. (600ml)",
    descricao2: "30 M.N. (1000ml)",
    extra: "",
    descricaoEN:"25 M.N. (600ml)",
    descricao2EN:"30 M.N. (1000ml)",
    extraEN: "",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "bebidasrefrescantes",
  },
  {
    active: true,
    id: "Aguamineral",
    name: "Agua mineral",
    nameEN: "Agua mineral",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "355ml",
    descricao2: "",
    extra: "",
    descricaoEN:"355ml",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 30",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasrefrescantes",
  },
  {
    active: true,
    id: "Limonada",
    name: "Limonada mineral	 | natural",
    nameEN: "Limonada mineral	 | natural",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 40",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasrefrescantes",
  },
  {
    active: true,
    id: "Jugos",
    name: "Jugos (Varios Sabores)",
    nameEN: "Jugos (Varios Sabores)",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "355ml",
    descricao2: "",
    extra: "",
    descricaoEN:"355ml",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 35",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasrefrescantes",
  },
  {
    active: true,
    id: "Refrescos",
    name: "Refrescos",
    nameEN: "Refrescos",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "355ml",
    descricao2: "",
    extra: "",
    descricaoEN:"355ml",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 35",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasrefrescantes",
  },
  {
    active: true,
    id: "Tehelado",
    name: "Té helado",
    nameEN: "Té helado",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "600ml",
    descricao2: "",
    extra: "",
    descricaoEN:"600ml",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 35",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasrefrescantes",
  },
  {
    active: true,
    id: "Gatorade",
    name: "Gatorade",
    nameEN: "Gatorade",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "600ml",
    descricao2: "",
    extra: "",
    descricaoEN:"600ml",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 40",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasrefrescantes",
  },
  {
    active: true,
    id: "Electrolit",
    name: "Electrolit",
    nameEN: "Electrolit",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "625ml",
    descricao2: "",
    extra: "",
    descricaoEN:"625ml",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 45",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasrefrescantes",
  },
  {
    active: true,
    id: "Redbull",
    name: "Red Bull",
    nameEN: "Red Bull",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "250ml",
    descricao2: "",
    extra: "",
    descricaoEN:"250ml",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 70",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasrefrescantes",
  },
  {
    active: true,
    id: "Monster",
    name: "Monster",
    nameEN: "Monster",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "473ml",
    descricao2: "",
    extra: "",
    descricaoEN:"473ml",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 70",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasrefrescantes",
  },
  {
    active: true,
    id: "Hielera",
    name: "Hielera",
    nameEN: "Hielera",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 20",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasrefrescantes",
  },
 
 

  /* CERVEJAS CIDRAS */
  {
    active: true,
    id: "Tecate",
    name: "Tecate",
    nameEN: "Tecate",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "355ml",
    descricao2: "Vaso Chelado o Michelado + 15 M.N.",
    extra: "Vaso Clamato + 40 M.N",
    descricaoEN:"355ml",
    descricao2EN:"Vaso Chelado o Michelado + 15 M.N.",
    extraEN: "Vaso Clamato + 40 M.N",
    preco: "$ 45",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "cervejascidras",
  },
  {
    active: true,
    id: "Tecatelight",
    name: "Tecate Light	",
    nameEN: "Tecate Light	",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "355ml",
    descricao2: "Vaso Chelado o Michelado + 15 M.N.",
    extra: "Vaso Clamato + 40 M.N",
    descricaoEN:"355ml",
    descricao2EN:"Vaso Chelado o Michelado + 15 M.N.",
    extraEN: "Vaso Clamato + 40 M.N",
    preco: "$ 45",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "cervejascidras",
  },
  {
    active: true,
    id: "Indio",
    name: "Indio",
    nameEN: "Indio",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "355ml",
    descricao2: "Vaso Chelado o Michelado + 15 M.N.",
    extra: "Vaso Clamato + 40 M.N",
    descricaoEN:"355ml",
    descricao2EN:"Vaso Chelado o Michelado + 15 M.N.",
    extraEN: "Vaso Clamato + 40 M.N",
    preco: "$ 45",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "cervejascidras",
  },
  {
    active: true,
    id: "XXLager",
    name: "XX Lager",
    nameEN: "XX Lager",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "355ml",
    descricao2: "Vaso Chelado o Michelado + 15 M.N.",
    extra: "Vaso Clamato + 40 M.N",
    descricaoEN:"355ml",
    descricao2EN:"Vaso Chelado o Michelado + 15 M.N.",
    extraEN: "Vaso Clamato + 40 M.N",
    preco: "$ 45",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "cervejascidras",
  },
  {
    active: true,
    id: "Amstel",
    name: "Amstel Ultra",
    nameEN: "Amstel Ultra",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "355ml",
    descricao2: "Vaso Chelado o Michelado + 15 M.N.",
    extra: "Vaso Clamato + 40 M.N",
    descricaoEN:"355ml",
    descricao2EN:"Vaso Chelado o Michelado + 15 M.N.",
    extraEN: "Vaso Clamato + 40 M.N",
    preco: "$ 45",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "cervejascidras",
  },
  {
    active: true,
    id: "Bohemia",
    name: "Bohemia Clásica",
    nameEN: "Bohemia Clásica",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "355ml",
    descricao2: "Vaso Chelado o Michelado + 15 M.N.",
    extra: "Vaso Clamato + 40 M.N",
    descricaoEN:"355ml",
    descricao2EN:"Vaso Chelado o Michelado + 15 M.N.",
    extraEN: "Vaso Clamato + 40 M.N",
    preco: "$ 45",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "cervejascidras",
  },
  {
    active: true,
    id: "6latas",
    name: "PACK 6 Latas",
    nameEN: "PACK 6 Latas",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "#AQUIEMPIEZALAPARTY",
    descricao2: "Vaso Chelado o Michelado + 15 M.N. | Vaso Clamato + 40 M.N",
    extra: "(No incluye Bohemia Clásica)",
    descricaoEN:"#AQUIEMPIEZALAPARTY",
    descricao2EN:"Vaso Chelado o Michelado + 15 M.N. | Vaso Clamato + 40 M.N",
    extraEN: "(No incluye Bohemia Clásica)",
    preco: "$ 220",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "cervejascidras",
  },
  {
    active: true,
    id: "12latas",
    name: "PACK 12 Latas",
    nameEN: "PACK 12 Latas",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "#AQUIEMPIEZALAPARTY",
    descricao2: "Vaso Chelado o Michelado + 15 M.N. | Vaso Clamato + 40 M.N",
    extra: "(No incluye Bohemia Clásica)",
    descricaoEN:"#AQUIEMPIEZALAPARTY",
    descricao2EN:"Vaso Chelado o Michelado + 15 M.N. | Vaso Clamato + 40 M.N",
    extraEN: "(No incluye Bohemia Clásica)",
    preco: "$ 440",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "cervejascidras",
  },

 
  /* BEBIDAS ESPIRITUOSAS  */
  {
    active: true,
    id: "COGNAC",
    name: "COGNAC",
    nameEN: "COGNAC",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Rémy Martin V.S.O.P.",
    descricao2: "COPA - 105 M.N.",
    extra: "BOTELLA - 1750 M.N. (750ml)",
    descricaoEN:"Rémy Martin V.S.O.P.",
    descricao2EN:"GLASS - 105 M.N.",
    extraEN: "BOTTLE - 1750 M.N. (750ml)",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  {
    active: true,
    id: "RON",
    name: "RON",
    nameEN: "RON",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Bacardi Blanco",
    descricao2: "COPA - 60 M.N.",
    extra: "BOTELLA - 550 M.N. (750ml)",
    descricaoEN:"Bacardi Blanco",
    descricao2EN:"GLASS - 60 M.N.",
    extraEN: "BOTTLE - 550 M.N. (750ml)",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  {
    active: true,
    id: "VODKA",
    name: "VODKA",
    nameEN: "VODKA",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Absolut",
    descricao2: "COPA - 65 M.N.",
    extra: "BOTELLA - 770 M.N. (750ml)",
    descricaoEN:"Absolut",
    descricao2EN:"GLASS - 65 M.N.",
    extraEN: "BOTTLE - 770 M.N. (750ml)",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  {
    active: true,
    id: "GINEBRA",
    name: "GINEBRA",
    nameEN: "GINEBRA",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Tanqueray",
    descricao2: "COPA - 70 M.N.",
    extra: "BOTELLA - 880 M.N. (750ml)",
    descricaoEN:"Tanqueray",
    descricao2EN:"GLASS - 70 M.N.",
    extraEN: "BOTTLE - 880 M.N. (750ml)",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  {
    active: true,
    id: "TEQUILA",
    name: "TEQUILA",
    nameEN: "TEQUILA",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Cazadores",
    descricao2: "COPA - 65 M.N.",
    extra: "BOTELLA - 880 M.N. (750ml)",
    descricaoEN:"Cazadores",
    descricao2EN:"GLASS - 65 M.N.",
    extraEN: "BOTTLE - 880 M.N. (750ml)",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  {
    active: true,
    id: "TEQUILA2",
    name: "TEQUILA",
    nameEN: "TEQUILA",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Don Julio Blanco",
    descricao2: "COPA - 70 M.N.",
    extra: "BOTELLA - 1000 M.N. (750ml)",
    descricaoEN:"Don Julio Blanco",
    descricao2EN:"GLASS - 70 M.N.",
    extraEN: "BOTTLE - 1000 M.N. (750ml)",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  {
    active: true,
    id: "TEQUILA3",
    name: "TEQUILA",
    nameEN: "TEQUILA",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Don Julio Reposado",
    descricao2: "COPA - 80 M.N.",
    extra: "BOTELLA - 1050 M.N. (750ml)",
    descricaoEN:"Don Julio Reposado",
    descricao2EN:"GLASS - 80 M.N.",
    extraEN: "BOTTLE - 1050 M.N. (750ml)",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  {
    active: true,
    id: "TEQUILA4",
    name: "TEQUILA",
    nameEN: "TEQUILA",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Don Julio Añejo",
    descricao2: "COPA - 100 M.N.",
    extra: "BOTELLA - 1370 M.N. (750ml)",
    descricaoEN:"Don Julio Añejo",
    descricao2EN:"GLASS - 100 M.N.",
    extraEN: "BOTTLE - 1370 M.N. (750ml)",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  {
    active: true,
    id: "TEQUILA5",
    name: "TEQUILA",
    nameEN: "TEQUILA",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Don Julio 70",
    descricao2: "COPA - 110 M.N.",
    extra: "BOTELLA - 1550 M.N. (750ml)",
    descricaoEN:"Don Julio 70",
    descricao2EN:"GLASS - 110 M.N.",
    extraEN: "BOTTLE - 1550 M.N. (750ml)",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  {
    active: true,
    id: "MEZCAL",
    name: "MEZCAL",
    nameEN: "MEZCAL",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "400 Conejos Joven",
    descricao2: "COPA - 80 M.N.",
    extra: "BOTELLA - 770 M.N. (750ml)",
    descricaoEN:"400 Conejos Joven",
    descricao2EN:"GLASS - 80 M.N.",
    extraEN: "BOTTLE - 770 M.N. (750ml)",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  {
    active: true,
    id: "LICOR",
    name: "TEQUILA",
    nameEN: "TEQUILA",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Kahlúa",
    descricao2: "COPA",
    extra: "",
    descricaoEN:"Kahlúa",
    descricao2EN:"GLASS",
    extraEN: "",
    preco: "$ 65",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  {
    active: true,
    id: "LICOR2",
    name: "TEQUILA",
    nameEN: "TEQUILA",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Caribe Cooler",
    descricao2: "BOTELLA (300ml)",
    extra: "",
    descricaoEN:"Caribe Cooler",
    descricao2EN:"BOTTLE (300ml)",
    extraEN: "",
    preco: "$ 70",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  {
    active: true,
    id: "LICOR3",
    name: "LICOR",
    nameEN: "LICOR",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "SKYY",
    descricao2: "BOTELLA (275ml)",
    extra: "",
    descricaoEN:"SKYY",
    descricao2EN:"BOTTLE (275ml)",
    extraEN: "",
    preco: "$ 70",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "bebidasespirituosas",
  },
  

  /* WHISKY */
  {
    active: true,
    id: "Etiqueta",
    name: "JW Etiqueta Negra",
    nameEN: "JW Etiqueta Negra",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "COPA - 105 M.N.",
    descricao2: "BOTELLA - 1300 M.N. (750ml)",
    extra: "",
    descricaoEN:"GLASS - 105 M.N.",
    descricao2EN:"BOTTLE - 1300 M.N. (750ml)",
    extraEN: "",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "whisky",
  },
  {
    active: true,
    id: "Buchanan12",
    name: "Buchanan’s 12 años",
    nameEN: "Buchanan’s 12 años",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "COPA - 105 M.N.",
    descricao2: "BOTELLA - 1300 M.N. (750ml)",
    extra: "",
    descricaoEN:"GLASS - 105 M.N.",
    descricao2EN:"BOTTLE - 1300 M.N. (750ml)",
    extraEN: "",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "whisky",
  },
  {
    active: true,
    id: "Buchanan18",
    name: "Buchanan’s 18 años	",
    nameEN: "Buchanan’s 18 años	",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "COPA - 220 M.N.",
    descricao2: "BOTELLA - 2700 M.N. (750ml)",
    extra: "",
    descricaoEN:"GLASS - 220 M.N.",
    descricao2EN:"BOTTLE - 2700 M.N. (750ml)",
    extraEN: "",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "whisky",
  },
  

  /* ESPECIAIS */
  {
    active: true,
    id: "Clamato",
    name: "Clamato preparado",
    nameEN: "Clamato preparado",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "SIN LICOR 40 M.N.",
    descricao2: "CON LICOR 70 M.N.",
    extra: "",
    descricaoEN:"NO LIQUOR 40 M.N.",
    descricao2EN:"WITH LIQUOR 70 M.N.",
    extraEN: "",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "especiais",
  },
  {
    active: true,
    id: "Pinacolada",
    name: "Piña colada",
    nameEN: "Piña colada",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "SIN LICOR 55 M.N.",
    descricao2: "CON LICOR 80 M.N.",
    extra: "",
    descricaoEN:"NO LIQUOR 55 M.N.",
    descricao2EN:"WITH LIQUOR 80 M.N.",
    extraEN: "",
    preco: "",
    moeda: "",
    grupo: "bebidas",
    subgrupo: "especiais",
  },
  {
    active: true,
    id: "Margarita",
    name: "Margarita",
    nameEN: "Piña colada",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 80",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "especiais",
  },
  {
    active: true,
    id: "Paloma",
    name: "Paloma",
    nameEN: "Paloma",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 85",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "especiais",
  },
  {
    active: true,
    id: "Sangría",
    name: "Sangría",
    nameEN: "Sangría",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "Vodka con jugo de naranja",
    descricao2: "",
    extra: "",
    descricaoEN:"Vodka con jugo de naranja",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 90",
    moeda: "M.N.",
    grupo: "bebidas",
    subgrupo: "especiais",
  },


  /* TABACO */
  {
    active: true,
    id: "Marlboro",
    name: "Marlboro Light | Rojo",
    nameEN: "Marlboro Light | Rojo",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 110",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "tabaco",
  },
  {
    active: true,
    id: "Benson",
    name: "Benson Dorado | Mentolado",
    nameEN: "Benson Dorado | Mentolado",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 110",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "tabaco",
  },
  {
    active: true,
    id: "Crystalviolet",
    name: "Benson Crystal Violet",
    nameEN: "Benson Crystal Violet",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 110",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "tabaco",
  },
  {
    active: true,
    id: "Pallmall",
    name: "Pall Mall",
    nameEN: "Pall Mall",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 100",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "tabaco",
  },

  /* HIGIENTE */
  {
    active: true,
    id: "Toalla",
    name: "Toalla femenina",
    nameEN: "Toalla femenina",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 20",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "higiene",
  },
  {
    active: true,
    id: "Rastrillo",
    name: "Rastrillo",
    nameEN: "Rastrillo",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 30",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "higiene",
  },
  {
    active: true,
    id: "Cepillo",
    name: "Cepillo dental",
    nameEN: "Cepillo dental",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 30",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "higiene",
  },
  {
    active: true,
    id: "Pastadental",
    name: "Pasta dental",
    nameEN: "Pasta dental",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 20",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "higiene",
  },
  {
    active: true,
    id: "Desodorante",
    name: "Desodorante",
    nameEN: "Desodorante",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 50",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "higiene",
  },
  {
    active: true,
    id: "Peine",
    name: "Peine",
    nameEN: "Peine",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 20",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "higiene",
  },
  {
    active: true,
    id: "Shampoo",
    name: "Shampoo",
    nameEN: "Shampoo",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 15",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "higiene",
  },
  {
    active: true,
    id: "Gel",
    name: "Gel para cabello",
    nameEN: "Gel para cabello",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 35",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "higiene",
  },
  {
    active: true,
    id: "Jabon",
    name: "Jabón",
    nameEN: "Jabón",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 35",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "higiene",
  },
  {
    active: true,
    id: "Crema",
    name: "Crema hidratante",
    nameEN: "Crema hidratante",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 35",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "higiene",
  },

  /* MEDICINA */
  {
    active: true,
    id: "Alkaseltzer",
    name: "Alkaseltzer",
    nameEN: "Alkaseltzer",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 15",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "medicine",
  },
  {
    active: true,
    id: "Aspirina",
    name: "Aspirina",
    nameEN: "Aspirina",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 15",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "medicine",
  },

  /* VARIOS */
  {
    active: true,
    id: "Cartas",
    name: "Baraja de cartas",
    nameEN: "Baraja de cartas",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 110",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "varios",
  },
  {
    active: true,
    id: "Encendedor",
    name: "Encendedor",
    nameEN: "Encendedor",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 35",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "varios",
  },
  {
    active: true,
    id: "Cargadoresmovil",
    name: "Cargadores móvil",
    nameEN: "Cargadores móvil",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 100",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "varios",
  },

  /* PRESERVATIVOS */
  {
    active: true,
    id: "Preservativoc3",
    name: "Preservativo C/3",
    nameEN: "Preservativo C/3",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 110",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "preservativos",
  },
  {
    active: true,
    id: "Preservativosabor",
    name: "Preservativo de sabor",
    nameEN: "Preservativo de sabor",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 40",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "preservativos",
  },
  {
    active: true,
    id: "Lubricante",
    name: "Lubricante",
    nameEN: "Lubricante",
    img: "./img/motel-los-pinos-logo.png",
    descricao: "",
    descricao2: "",
    extra: "",
    descricaoEN:"",
    descricao2EN:"",
    extraEN: "",
    preco: "$ 40",
    moeda: "M.N.",
    grupo: "outros",
    subgrupo: "preservativos",
  },

 /* CATALOGO EROTICO */
 {
  active: true,
  id: "Macacão",
  name: "Macacão",
  nameEN: "Macacão",
  img: "./img/catalogoerotico/macacao.png",
  descricao: "Macacão sensual.",
  descricao2: "",
  extra: "Tamanho S-L",
  descricaoEN: "Macacão sensual.",
  descricao2EN: "",
  extraEN: "Size S-L",
  preco: "19,90",
  moeda: "€",
  grupo: "catalogoerotico",
  subgrupo: "",
},
{
  active: true,
  id: "Ref.1",
  name: "Lubrificante Silicone",
  nameEN: "Lubrificante Silicone",
  img: "./img/catalogoerotico/ref1.jpg",
  descricao: "Vodka e Red Bull.",
  descricao2: "Ref.1",
  extra: "35 ml",
  descricaoEN: "Vodka e Red Bull.",
  descricao2EN: "Ref.1",
  extraEN: "35 ml",
  preco: "16,00",
  moeda: "€",
  grupo: "catalogoerotico",
  subgrupo: "",
},

];
