import React from "react";
import { Grid, Container, Header } from "semantic-ui-react";

import { options } from "./options";

import { Template } from "./Template";

import globalVar from '../../globalVar';

export class Menu extends React.Component {
  render() {
    const optionsJSX = [];
    options.forEach((option) => {
      if (option.active) {
        optionsJSX.push(
          <Grid.Column
            key={option.id}
            mobile={16}
            tablet={8}
            computer={4}
            className=""
            onClick={() => this.props.selectPage("menu", option.id)}
          >
            <div
              className="banner"
              style={{
                backgroundImage: `url(${option.img})`,
              }}
            >
              <div class="overlay">
                <Header as="h3" inverted className="banner-label-position">
                  {globalVar.value == 0 ? (option.title) : (option.titleEN)}
                </Header>
              </div>
            </div>
          </Grid.Column>
        );
      }
    });

    const subid = this.props.menus.filter((menu) => menu.id === "menu")[0]
      .subid;

    if (subid === "") {
      return (
        <Container className="h-100 padding-0 margin-0 bg-grey" fluid>
          <Grid centered columns={1} className="h-100 margin-0 padding-0">
            <Grid.Row verticalAlign="top">
              <Grid.Column>
                <Grid>{optionsJSX}</Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    } else {
      const option = options.filter((option) => option.id === subid);
      return (
        <Template
          title={option[0].title}
          titleEN={option[0].titleEN}
          img={option[0].img}
          categoria={option[0].id}
        />
      );
    }
  }
}
