export const options = [
  {
    active: true,
    id: "pequenoalmoco",
    title: "Desaynunos",
    titleEN: "Breakfast",
    img: "./img/menu/pequenoalmoco/pequeno-almoco.jpg",
  },
  {
    active: true,
    id: "acompanhamentos",
    title: "Botanas",
    titleEN: "Side Dishes",
    img: "./img/menu/acompanhamentos/acompanhamentos.jpg",
  },
  // {
  //   active: true,
  //   id: "cafetaria",
  //   title: "Cafetaria",
  //   img: "./img/menu/cafetaria/cafetaria.jpg",
  // },
  // {
  //   active: true,
  //   id: "cafechachocolate",
  //   title: "Café - Chá - Chocolate",
  //   img: "./img/menu/cafechachocolate/cafechachocolate.jpg",
  // },
  {
    active: true,
    id: "entradassopas",
    title: "Entradas",
    titleEN: "Appetizer",
    img: "./img/menu/entradassopas/entradassopas.jpg",
  },
  {
    active: true,
    id: "saladas",
    title: "Ensaladas",
    titleEN: "Salads",
    img: "./img/menu/saladas/saladas.jpg",
  },
  {
    active: true,
    id: "comerconlasmanos",
    title: "Para comer con las manos",
    titleEN: "To eat with your hands",
    img: "./img/menu/snacks/snacks.jpg",
  },
  {
    active: true,
    id: "platillos",
    title: "Platillos",
    titleEN: "Food Dishes",
    img: "./img/menu/carnes/carnes.jpg",
  },
  {
    active: true,
    id: "postres",
    title: "Postres",
    titleEN: "Desserts",
    img: "./img/menu/sobremesas/sobremesas.jpg",
  },
  // {
  //   active: true,
  //   id: "massaspizzas",
  //   title: "Massas & Pizzas",
  //   img: "./img/menu/massaspizzas/massaspizzas.jpg",
  // },
  // {
  //   active: true,
  //   id: "peixes",
  //   title: "Peixes",
  //   img: "./img/menu/peixes/peixes.jpg",
  // },
];
