import React from "react";

import { Inicio } from "./Inicio/Inicio";
import { Menu } from "./Menu/Menu";
import { Bebidas } from "./Bebidas/Bebidas";
import { Outros } from "./Outros/Outros";
import { Tabaco } from "./Tabaco/Tabaco";
import { Sugestoes } from "./Sugestoes/Sugestoes";
import { CatalogoErotico } from "./CatalogoErotico/CatalogoErotico";
import { Tv } from "./TV/Tv";
import { Carrinho } from "./Carrinho/Carrinho";
import { Home } from "./Inicio/Home";

import { NotFoundPage } from "./NotFoundPage";

export class MyCentro extends React.Component {
  render() {
    const menuSelected = [];
    this.props.menus.forEach((menu) => {
      if (menu.selected) {
        if (menu.id === "inicio") {
          menuSelected.push(
            <Inicio
              selectPage={this.props.selectPage}
              menus={this.props.menus}
            />
          );
        } else if (menu.id === "home"){
          menuSelected.push(
            <Home selectPage={this.props.selectPage} menus={this.props.menus} />
          );
        } else if (menu.id === "menu") {
          menuSelected.push(
            <Menu selectPage={this.props.selectPage} menus={this.props.menus} />
          );
        } else if (menu.id === "bebidas") {
          menuSelected.push(
            <Bebidas
              selectPage={this.props.selectPage}
              menus={this.props.menus}
            />
          );
        } else if( menu.id === "outros" ){
          menuSelected.push(
            <Outros
              selectPage={this.props.selectPage}
              menus={this.props.menus}
            />
          );
        } else if (menu.id === "sugestoes") {
          menuSelected.push(
            <Sugestoes title={menu.text} img={menu.img} categoria={menu.id} />
          );
        } else if (menu.id === "catalogoerotico") {
          menuSelected.push(
            <CatalogoErotico
              loginCatalogoErotico={this.props.loginCatalogoErotico}
              catalogoEroticoIsLogIn={this.props.catalogoEroticoIsLogIn}
              selectPage={this.props.selectPage}
            />
          );
        } else if (menu.id === "carrinho") {
          menuSelected.push(<Carrinho carrinho={this.props.carrinho} />);
        } else if (menu.id === "tabaco") {
          menuSelected.push(
            <Tabaco
              title={menu.text}
              img={menu.img}
              categoria={menu.id}
              loginTabaco={this.props.loginTabaco}
              tabacoIsLogIn={this.props.tabacoIsLogIn}
              selectPage={this.props.selectPage}
            />
          );
        } else if (menu.id === "tv") {
          menuSelected.push(<Tv />);
        } else {
          menuSelected.push(<NotFoundPage />);
        }
      }
    });

    if (menuSelected === []) menuSelected.push(<NotFoundPage />);

    return (
      <div id="mainCentro" className="bg-grey h-100">
        {menuSelected}
      </div>
    );
  }
}
