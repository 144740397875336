import React from "react";
import {
  Grid,
  Container,
  Card,
  Item,
  Image,
  Modal,
  Form,
  Input,
  Button,
  Header,
} from "semantic-ui-react";

import { produtos } from "../../Dados/produtos";

import globalVar from '../../globalVar';

export class CatalogoErotico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalSRC: "",
      passwordInput: "",
      passwordErro: false,
      produtos: produtos.filter(
        (produto) => produto.active && produto.grupo === "catalogoerotico"
      ),
    };
  }

  modalOpen = (src) => {
    this.setState({
      modalIsOpen: true,
      modalSRC: src,
    });
  };

  modalClose = () => {
    this.setState({
      modalIsOpen: false,
      modalSRC: "",
    });
  };

  handleChange = (event) => {
    const isCheckbox = event.target.type === "checkbox";
    this.setState({
      [event.target.name]: isCheckbox
        ? event.target.checked
        : event.target.value,
    });
  };

  loginCatalogoErotico = (event) => {
    event.preventDefault();
    this.props.loginCatalogoErotico();
  };

  render() {
    let ItemsJSX = [];
    if (this.props.catalogoEroticoIsLogIn) {
      this.state.produtos.forEach((produto) => {
        ItemsJSX.push(
          <Grid.Column
            fluid
            stretched
            mobile={16}
            tablet={8}
            computer={4}
            key={produto.id}
            onClick={() => this.modalOpen(produto.img)}
          >
            <Card fluid className="padding-10 padding-top-15 column-card">
              <Image centered size="tiny" src={produto.img} />
              <Card.Content className="border-0">
                {produto.descricao2 !== "" ? ( // para o caso
                  <div>
                    <Card.Header
                      textAlign="center"
                      className="grid-produto-title"
                    >
                      {globalVar.value == 0 ? (produto.descricao2) : (produto.descricao2EN)}
                    </Card.Header>
                  </div>
                ) : null}

                <Card.Header textAlign="center" className="grid-produto-title">
                  {produto.name}
                </Card.Header>
                <Card.Header textAlign="center" className="grid-produto-preco">
                  {produto.preco} {" " + produto.moeda}
                </Card.Header>

                {produto.descricao !== "" ? (
                  <div>
                    <br />
                    <Item.Description className="text-align-center">
                      {globalVar.value == 0 ? (produto.descricao) : (produto.descricaoEN)}
                    </Item.Description>
                  </div>
                ) : null}

                {produto.extra !== "" ? (
                  <Item.Extra className="text-align-center">
                    {globalVar.value == 0 ? (produto.extra) : (produto.extraEN)}
                  </Item.Extra>
                ) : null}
              </Card.Content>
            </Card>
          </Grid.Column>
        );
      });
    } else {
      ItemsJSX.push(
        <Grid.Column mobile={16} tablet={14} computer={10}>
          <Grid centered className="margin-top10">
            <Grid.Column mobile={14} tablet={10} computer={8}>
              <Grid centered className="margin-top10">
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {globalVar.value == 0 
                    ? 
                    <Header as="h3" centered textAlign="center">
                      <div className="text-color-preto">
                        <p>Las imágenes presentadas son meramente ilustrativas.<br />
                          Todos los valores con IVA incluido al tipo vigente.<br />
                          Para realizar su pedido marque el 202 en el teléfono de la habitación.</p>
                      </div><br></br>
                      Página destinada a mayores de 18 años.
                    </Header>
                    : 
                    <Header as="h3" centered textAlign="center">
                      <div className="text-color-preto">
                        <p>The images presented are merely illustrative.<br />
                          All values ​​with VAT included at the current rate.<br />
                          To place your order, dial 202 on the room phone.</p>
                      </div><br></br>
                        Page intended for people over 18 years of age.
                    </Header>
                  }

                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    fluid
                    centered
                    type="submit"
                    onClick={this.loginCatalogoErotico}
                  >
                    {globalVar.value == 0 ? "Entrar" : "Get In"}
                  </Button>
                </Grid.Column>
                <Grid.Column mobile={8} tablet={8} computer={8}>
                  <Button
                    fluid
                    centered
                    type="submit"
                    onClick={() => this.props.selectPage("inicio", "")}
                  >
                    {globalVar.value == 0 ? "Salir" : "Leave"}
                  </Button>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      );
    }

    return (
      <div className="h-100">
        {/**Modal */}
        <Modal
          size="mini"
          open={this.state.modalIsOpen}
          onClick={this.modalClose}
          onClose={this.modalClose}
        >
          <Modal.Content image centered center>
            <Image wrapped size="medium" src={this.state.modalSRC} />
          </Modal.Content>
        </Modal>
        {/**Pagina Principal */}
        <Container className="h-100">
          <Grid centered columns={1} fluid className="h-100 margin-0 padding-0">
            <Grid.Row verticalAlign={this.props.catalogoEroticoIsLogIn ? "top" : "middle"}>
              <Grid.Column>
                <Grid
                  centered={this.props.catalogoEroticoIsLogIn ? false : true}
                  className="margin-top10"
                >
                  {ItemsJSX}
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}
