import React from "react";
import { Grid, Container, Item, Header, Card, Image } from "semantic-ui-react";

import { produtos } from "../../Dados/produtos";

export class Sugestoes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      produtos: produtos.filter(
        (produto) => produto.active && produto.onsugestoes === true
      ),
    };
  }

  render() {
    let ItemsJSX = [];

    ItemsJSX.push(
      <Grid.Column
        stretched
        mobile={16}
        tablet={16}
        computer={16}
        key="banner"
        fluid
        className="padding-0"
      >
        <div
          className="banner-inside-category"
          style={{
            backgroundImage: `url(${"" + this.props.img})`,
          }}
        >
          <div class="overlay">
            <Header as="h3" inverted className="banner-label-position">
              {this.props.title}
            </Header>
          </div>
        </div>
      </Grid.Column>
    );

    this.state.produtos.forEach((produto) => {
      ItemsJSX.push(
        <Grid.Column
          stretched
          mobile={16}
          tablet={8}
          computer={4}
          key={produto.id}
          fluid
        >
          <Card fluid className="padding-10 padding-top-15 column-card">
            <Image centered size="tiny" src={produto.img} />
            <Card.Content className="border-0">
              <Card.Header textAlign="center" className="grid-produto-title">
                {produto.name}
              </Card.Header>
              <Card.Header textAlign="center" className="grid-produto-preco">
                {produto.preco} {" " + produto.moeda}
              </Card.Header>
              {produto.descricao !== "" ? (
                <div>
                  <br />
                  <Item.Description className="text-align-center">
                    {produto.descricao}
                  </Item.Description>
                </div>
              ) : null}

              {produto.descricao2 !== "" ? (
                <div>
                  <br />
                  <Item.Description className="text-align-center">
                    {produto.descricao2}
                  </Item.Description>
                </div>
              ) : null}

              {produto.extra !== "" ? (
                <div>
                  <br />
                  <Item.Extra className="text-align-center">
                    {produto.extra}
                  </Item.Extra>
                </div>
              ) : null}
            </Card.Content>
          </Card>
        </Grid.Column>
      );
    });

    return (
      <div className="mainP1">
        {/*<div
            className="banner-inside-category"
            style={{
              backgroundImage: `url(${"" + this.props.img})`,
            }}
          >
            <Header as="h3" inverted className="banner-label-position">
              {this.props.title}
            </Header>
          </div> */}
        <div className="mainCentroP1 bg-grey">
          <Container fluid className="minh-100">
            <Grid centered columns={1} className="minh-100 margin-0 padding-0">
              <Grid.Row verticalAlign="top" className="padding-0">
                <Grid.Column>
                  <Grid className="margin-top0">{ItemsJSX}</Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}
