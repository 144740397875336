import React from "react";
import {
  Grid,
  Container,
  Item,
  Header,
  Card,
  Image,
  Button,
} from "semantic-ui-react";

import { produtos } from "../../Dados/produtos";

export class Carrinho extends React.Component {
  render() {
    let ItemsJSX = [];
    console.log(this.props.carrinho);
    this.props.carrinho.forEach((produto) => {
      ItemsJSX.push(
        <Grid.Column
          stretched
          mobile={16}
          tablet={8}
          computer={4}
          key={produto.id}
        >
          <Card className="padding-10 padding-top-15 column-card">
            <Image centered size="tiny" src={produto.img} />
            <Card.Content className="border-0">
              <Card.Header textAlign="center" className="grid-produto-title">
                {produto.name}
              </Card.Header>
              <Card.Header textAlign="center" className="grid-produto-preco">
                {produto.preco} {" " + produto.moeda}
              </Card.Header>
              {produto.descricao !== "" ? (
                <div>
                  <br />
                  <Item.Description className="text-align-center">
                    {produto.descricao}
                  </Item.Description>
                </div>
              ) : null}

              {produto.descricao2 !== "" ? (
                <div>
                  <br />
                  <Item.Description className="text-align-center">
                    {produto.descricao2}
                  </Item.Description>
                </div>
              ) : null}

              {produto.extra !== "" ? (
                <Item.Extra className="text-align-center">
                  {produto.extra}
                </Item.Extra>
              ) : null}

              <Button
                fluid
                centered
                icon="cart"
                label={{ as: "a", basic: true, content: produto.qtd }}
                labelPosition="right"
              />
            </Card.Content>
          </Card>
        </Grid.Column>
      );
    });

    console.log(this.props.img);

    return (
      <div className="mainP1">
        <div
          className="banner-inside-category"
          style={{
            backgroundImage: `url(${"" + this.props.img})`,
          }}
        >
          <Header as="h3" inverted className="banner-label-position">
            {this.props.title}
          </Header>
        </div>
        <div className="mainCentroP1 bg-grey">
          <Container className="minh-100">
            <Grid centered columns={1} className="minh-100 margin-0 padding-0">
              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <Grid className="margin-top10">{ItemsJSX}</Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}
